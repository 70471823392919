<template>
  <div class="itemAba" :class="{'is-loading': loading}">
    <produtividade :processo="processo.id" full-component disable-stats class="wrapper m-t-xxl m-b">
      App indisponível no momento.
    </produtividade>
  </div>
</template>

<script>
import AppMixin from "@/components/pessoa/components/include/pessoa/apps/appMixin"
import Produtividade from "@/components/processos/components/produtividade/Produtividade"

export default {
  name: "ProcessoEtapas",
  components: {Produtividade},
  props: ['processo'],
  mixins: [AppMixin]
}
</script>

